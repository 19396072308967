.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-shadow {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-color: #fff;
}
.fixed-width-container {
  width: 110px; /* or whatever width you want. */
  max-width: 110px; /* or whatever width you want. */
  display: inline-block;
}

.fixed-width-text {
  display: inline-block;
  width: 100%;
  white-space: nowrap; /* Prevent wrapping */
}
.marquee {
  width: 500px;
  overflow: hidden;
  white-space: nowrap;
}

.marquee-container {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.marquee-content {
  display: inline-flex;
  animation: marquee 20s linear infinite; /* Adjust duration */
}

.marquee-item {
  padding-left: 10px; /* Add spacing between repeated items */
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.collapsible-container {
  margin-bottom: 1em;
}

.collapsible-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.toggle-button {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.collapsible-content {
  padding: 1em;
  border-top: 1px solid #ccc;
  background-color: #fff;
}
