:root {
  --selected-color: #17a2b8;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month--selecting-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__input-time-container,
.react-datepicker__time,
.react-datepicker__day:hover,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selecting-range-start:hover,
.react-datepicker__time-list-item:hover,
.react-datepicker__time-container,
.react-datepicker__time-list-item--selected,
.react-datepicker__close-icon::after,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: var(--selected-color);
}
.react-datepicker__day--selected
  .react-datepicker__day--in-selecting-range
  .react-datepicker__day--in-range
  .react-datepicker__day--selected:hover
  .react-datepicker__day--in-selecting-range:hover {
  background-color: var(--selected-color);
}
.react-datepicker__day--in-range:hover
  .react-datepicker__month--selecting-range
  .react-datepicker__year-text--selected
  .react-datepicker__year-text--in-selecting-range {
  background-color: var(--selected-color);
}
.react-datepicker__time
  .react-datepicker__day:hover
  .react-datepicker__day--keyboard-selected
  .react-datepicker__day--selecting-range-start:hover
  .react-datepicker__time-list-item:hover {
  background-color: var(--selected-color);
}
/* For mobile view */
@media only screen and (max-width: 767px) {
  .react-datepicker__month-container {
    float: none;
  }
}
@media only screen and (max-width: 768px) {
  .react-datepicker__month-container {
    float: initial;
  }
}
